exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikel-js": () => import("./../../../src/pages/artikel.js" /* webpackChunkName: "component---src-pages-artikel-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-fuer-kunden-bestellen-js": () => import("./../../../src/pages/fuer-kunden-bestellen.js" /* webpackChunkName: "component---src-pages-fuer-kunden-bestellen-js" */),
  "component---src-pages-honorare-js": () => import("./../../../src/pages/honorare.js" /* webpackChunkName: "component---src-pages-honorare-js" */),
  "component---src-pages-honorare-uebersicht-js": () => import("./../../../src/pages/honorare/uebersicht.js" /* webpackChunkName: "component---src-pages-honorare-uebersicht-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kollegenempfehlung-js": () => import("./../../../src/pages/kollegenempfehlung.js" /* webpackChunkName: "component---src-pages-kollegenempfehlung-js" */),
  "component---src-pages-kundenbestellabfrage-js": () => import("./../../../src/pages/kundenbestellabfrage.js" /* webpackChunkName: "component---src-pages-kundenbestellabfrage-js" */),
  "component---src-pages-lavita-verschenken-js": () => import("./../../../src/pages/lavita-verschenken.js" /* webpackChunkName: "component---src-pages-lavita-verschenken-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mediathek-js": () => import("./../../../src/pages/mediathek.js" /* webpackChunkName: "component---src-pages-mediathek-js" */),
  "component---src-pages-partnererfahrungen-js": () => import("./../../../src/pages/partnererfahrungen.js" /* webpackChunkName: "component---src-pages-partnererfahrungen-js" */),
  "component---src-pages-studien-js": () => import("./../../../src/pages/studien.js" /* webpackChunkName: "component---src-pages-studien-js" */),
  "component---src-pages-termin-js": () => import("./../../../src/pages/termin.js" /* webpackChunkName: "component---src-pages-termin-js" */),
  "component---src-pages-vorteilspaket-js": () => import("./../../../src/pages/vorteilspaket.js" /* webpackChunkName: "component---src-pages-vorteilspaket-js" */),
  "component---src-pages-wissenschaftlicher-beirat-js": () => import("./../../../src/pages/wissenschaftlicher-beirat.js" /* webpackChunkName: "component---src-pages-wissenschaftlicher-beirat-js" */),
  "component---src-templates-experiences-article-js": () => import("./../../../src/templates/experiences-article.js" /* webpackChunkName: "component---src-templates-experiences-article-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-study-js": () => import("./../../../src/templates/study.js" /* webpackChunkName: "component---src-templates-study-js" */)
}

