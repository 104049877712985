import "./src/styles/global.css"
import "./src/shareact/styles/icon-font.css"
import "./src/shareact/styles/reset.css"
import "./src/i18n/config"
import "./src/styles/toast.css"
import "intersection-observer" // polyfill for IntersectionObserver

import Tracker from "@openreplay/tracker/cjs"
import trackerAssist from "@openreplay/tracker-assist"
import wrapPageElement from "./src/utils/wrap-page"
import wrapRootElement from "./src/utils/wrap-root"

const onClientEntry = () => {
    if (typeof window.tracker !== "undefined" || process.env.NODE_ENV === "development") return

    window.tracker = new Tracker({
        projectKey: process.env.OPENREPLAY_KEY,
        ingestPoint: process.env.OPENREPLAY_URL,
        obscureTextEmails: false,
        obscureInputEmails: false,
        captureIFrames: false,
        __DISABLE_SECURE_MODE: window.location.hostname === "localhost",
    })
    window.tracker.use(trackerAssist({}))
    window.tracker.start()
}

export { onClientEntry, wrapPageElement, wrapRootElement }
